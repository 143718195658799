<template>
    <div class="page">
        <van-nav-bar :border="false" :placeholder="true" :fixed="true" :title="$t('银行卡绑定')" left-arrow
                     @click-left="onClickLeft">
        </van-nav-bar>
        <div class="wrapper">
            <van-form class="bs-form">
                <van-field
                        readonly
                        clickable
                        name="picker"
                        v-model="bank_text"
                        :label="$t('银行名称')"
                        :placeholder="$t('请选择银行')"
                        @click="showPicker = true" label-width="40%"
                >
                    <template #label><span class="field-label">{{$t('银行名称')}}</span></template>
                    <template #right-icon><van-icon name="arrow" /></template>
                </van-field>
                <!-- <van-field
                        clickable
                        v-model="bank_text"
                        :label="$t('银行名称')"
                        :placeholder="$t('请输入银行名称')" label-width="40%"
                >
                    <template #label><span class="field-label">{{$t('银行名称')}}</span></template>
                </van-field> -->
                <van-field  v-model="number" :placeholder="$t('请输入银行卡号')" >
                    <template #label><span class="field-label">{{$t('银行卡号')}}</span></template>
                </van-field>
                <van-field  v-model="name" :placeholder="$t('请输入付款人姓名')">
                    <template #label><span class="field-label">{{$t('持卡人姓名')}}</span></template>
                </van-field>
                <van-field readonly clickable v-model="formattedDate" :placeholder="$t('请输入出生日期')" @click="showDatePicker = true">
                    <template #label><span class="field-label">{{$t('出生日期')}}</span></template>
                    <template #right-icon><van-icon name="arrow" /></template>
                </van-field>
                <van-field v-if="false"  v-model="remark" type="textarea" rows="3" center :label="$t('备注信息')" :placeholder="$t('可不填')" label-width="40%" >
                </van-field>
            </van-form>
            <div class="wt-tips">
                <div class="wt-label text-green">{{$t('绑定提示')}}</div>
                <div class="wt-body text-gray" v-if="this.$t('绑定提示1') != '绑定提示1'" v-html="$t('绑定提示1')"></div>
                <div class="wt-body text-gray" v-if="this.$t('绑定提示2') != '绑定提示2'" v-html="$t('绑定提示2')"></div>
                <div class="wt-body text-gray" v-if="this.$t('绑定提示3') != '绑定提示3'" v-html="$t('绑定提示3')"></div>
                <div class="wt-body text-red" v-if="this.$t('绑定提示4') != '绑定提示4'" v-html="$t('绑定提示4')"></div>
            </div>
            <div class="btn-space btn-fixed">
                <van-button type="primary" block round @click="handleClickSave">{{$t('确认')}}</van-button>
            </div>
        </div>
        
        <van-popup v-model="showPicker" position="bottom" round>
            <van-picker
                    show-toolbar
                    :columns="columns"
                    @confirm="onConfirm"
                    @cancel="showPicker = false"
                    :confirm-button-text="$t('确认')"
                    :cancel-button-text="$t('取消')"
            />
        </van-popup>
        <van-popup v-model="showPickerUSDT" position="bottom" round>
            <van-picker
                    show-toolbar
                    :columns="columns_usdt"
                    @confirm="onConfirm_usdt"
                    @cancel="showPickerUSDT = false"
                    :confirm-button-text="$t('确认')"
                    :cancel-button-text="$t('取消')"
            />
        </van-popup>
        <van-popup v-model="showDatePicker" round position="bottom">
            <van-datetime-picker
              v-model="currentDate"
              type="date"
              :title="$t('出生日期')"
              :max-date="maxDate"
              :min-date="minDate"
              :columns-order="['day', 'month', 'year']"
              :confirm-button-text="$t('确认')"
              :cancel-button-text="$t('取消')"
              @confirm="onDateConfirm"
              @cancel="showDatePicker = false"
            />
          </van-popup>
    </div>
</template>

<script>
    import helper from "../../utils/helper";

    export default {
        components: {},
        data() {
            return {
                active: 0,
                loading: false,
                showPicker: false,
                showPickerUSDT: false,
                id: 0,
                name: '',
                bank_name: '',
                bank_text: '',
                bank_branch:'',
                number: '',
                remark: '',
                subbranch:'',
                columns: [],
                usdt_show : false,
                columns_usdt: [
                    'ERC20', 'TRC20', 'HECO', 'OMNI'
                ],
                usdt_name: '',
                usdt_address: '',
                showDatePicker:false,
                currentDate: new Date(),
                maxDate: new Date(2005, 11, 31),
                minDate: new Date(1900, 1, 1),
            }
        },
        methods: {
            onClickLeft() {
                this.$router.back();
            },
            onConfirm(value, index) {
                this.bank_text = value;
                this.bank_name = this.banks[index];
                this.showPicker = false;
            },
            onDateConfirm(date){
                this.subbranch = date;
                this.showDatePicker = false;
            },
            onConfirm_usdt(value, index) {
                this.usdt_name = value;
                this.showPickerUSDT = false;
            },
            getData() {
                this.$axios.get(this.$api_url.get_bind_bank + '?id=' + this.id).then((res) => {
                    let data = res.data;
                    if (data.code == 0) {
                        this.active = data.data.info.type ??0;
                        this.usdt_show = data.data.usdt == 1 ??false;
                        if(this.active == 1){
                            this.usdt_name = data.data.info.bank_name;
                            this.usdt_address = data.data.info.number;
                        }else{
                            this.bank_name = data.data.info.bank_name;
                            this.bank_text = data.data.list[0];
                            this.bank_branch = data.data.info.bank_branch;
                            this.name = data.data.info.name;
                            this.active = data.data.info.type ??0;
                            this.number = data.data.info.number;
                            this.remark = data.data.info.remark;
                            this.columns = data.data.list;
                            this.banks = data.data.banks;
                        }
                    }
                }).catch((err) => {
                    this.$toast.fail(err.message);
                });
            },
            handleClickSave() {
                if(this.bank_text == ''){
                    this.$toast.fail(this.$t('请输入银行名称！'));
                    return;
                }
                if(this.number == ''){
                    this.$toast.fail(this.$t('请输入银行卡号'));
                    return;
                }
                if(this.name == ''){
                    this.$toast.fail(this.$t('请输入付款人姓名'));
                    return;
                }
                if(this.formattedDate == ''){
                    this.$toast.fail(this.$t('请输入出生日期'));
                    return;
                }
                this.loading = true;
                let loading = this.$toast.loading();
                let _data = {};
                let url =  this.$api_url.save_bind_bank;
                if(this.active == 0){
                    _data = {
                        id: this.id,
                        type: 0,
                        name: this.name,
                        bank_name: this.bank_text,
                        bank_branch: this.bank_branch,
                        number: this.number,
                        remark: this.remark,
                        subbranch: this.formattedDate,
                    };
                }else{
                    url = '/bank/save_usdt';
                    _data = {
                        id: this.id,
                        type: 1,
                        bank_name: this.usdt_name,
                        number: this.usdt_address,
                    };
                }
                let data = helper.encrypt(JSON.stringify(_data));
                this.$axios
                    .post(
                        url,
                        this.$qs.stringify({
                            data: data,
                        })
                    )
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.$toast.success(res.data.msg);
                            this.$router.push({path: '/bank'})
                        } else {
                            this.$toast.fail({duration: 3000, message: res.data.msg});
                        }
                    })
                    .catch((err) => {
                        this.$toast.fail(err.message);
                    })
                    .then(() => {
                        // loading.clear();
                        this.loading = false;
                    });
            },
        },
        computed: {
            formattedDate() {
            if (!this.subbranch) return '';
            const date = new Date(this.subbranch);
            return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
            }
        },
        mounted() {
            if (this.$route.query.id !== undefined) {
                this.id = this.$route.query.id;
            }
            this.getData();
        }

    }
</script>

<style scoped="scoped">
    .tri-cellss {
        font-size: 14px;
        padding: 0 0 20px 0;
    }
    .wt-tips{
        padding:15px 0;
        font-size: 14px;
    }
    .wt-tips .wt-label{
        margin-bottom: 6px;
    }

</style>
